<template>
    <mf-modal small :scrollable="false" v-if="isOpen" @close="close">
        <div slot="header">
            Create a new quay relation
        </div>
        <div class="modal__title">Source quay: {{ sourceQuay.name }}</div>
        <div class="modal__input">
            <mf-input placeholder="Relation name" v-model="name" />
        </div>
        <div class="modal__select">
            <mf-select
                class="quay-select"
                placeholder="Select quay for add"
                :options="possibleQuays"
                label="name"
                v-model="quay"
                @select="handleSelectQuay"
            />
        </div>
        <div slot="footer">
            <div class="modal__buttons">
                <mf-button color="blue" @click="addQuay" :disabled="isDisabledButton">Add</mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'CreateQuayRelationModal',
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
            sourceQuay: {
                type: Object,
                required: true,
                default: () => {},
            },
        },
        data() {
            return {
                name: '',
                quay: '',
            };
        },
        computed: {
            ...mapGetters(['quaysNames', 'selectedQuay']),
            isDisabledButton() {
                return !(this.quay && this.name);
            },
            possibleQuays() {
                return this.quaysNames.filter(quay => quay.id !== this.selectedQuay.id);
            },
        },
        methods: {
            close() {
                this.$emit('close');
            },
            handleSelectQuay(quay) {
                this.quay = quay;
            },
            addQuay() {
                this.$emit('newQuayRelation', {
                    relationName: this.name,
                    quay: this.quay,
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal__title {
        color: $white;
        font-size: $font-18;
        margin-bottom: $space-16;
    }
    .modal__input {
        margin-bottom: $space-20;
    }
    .modal__buttons {
        display: flex;
        justify-content: flex-end;
    }
</style>
