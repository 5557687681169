<template>
    <mf-box>
        <div slot="header">
            Quay Group
        </div>
        <div class="quay-group">
            <div class="container__row">
                <div class="container__item">
                    <span class="container__title">
                        Groups
                    </span>
                    <div class="group-list">
                        <div
                            v-for="group in groups"
                            :key="`group-${group.id}`"
                            class="group-item"
                            :class="{ 'group-item_selected': selectedGroup && group.id === selectedGroup.id }"
                            @click.capture="handleClickGroup(group)"
                        >
                            {{ group.name }}
                            <mf-button
                                class="group-item__button"
                                size="x-small"
                                @click.capture="handleRemoveGroup(group.id)"
                            >
                                Remove
                            </mf-button>
                        </div>
                    </div>
                    <div class="buttons-container">
                        <mf-button size="x-small" @click="toggleModal">Add a new group</mf-button>
                    </div>
                </div>
                <div class="container__item">
                    <span class="container__title">
                        Grouped Quays
                    </span>
                    <div class="quays-list" v-if="selectedGroup && selectedGroup.quays.length">
                        <template>
                            <div v-for="quay in selectedGroup.quays" :key="`quay-${quay.id}`" class="quay-item">
                                {{ quay.convertedName }}
                                <mf-button class="quay-item__button" size="x-small" @click="handleRemoveQuay(quay.id)">
                                    Remove
                                </mf-button>
                            </div>
                        </template>
                    </div>
                    <div class="buttons-container" v-if="selectedGroup">
                        <quay-select @input="handleSelectQuay" :options="quaysNames" placeholder="Search quay to add" />
                    </div>
                </div>
            </div>
        </div>
        <create-group-modal :is-open="isCreateModalShown" @close="toggleModal" @handleNewGroupName="handleNewGroup" />
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import CreateGroupModal from '@/modals/CreateGroupModal';
    import QuaySelect from '@/components/QuaySelect';

    export default {
        name: 'QuayGroup',
        components: {
            QuaySelect,
            CreateGroupModal,
        },
        data() {
            return {
                isCreateModalShown: false,
            };
        },
        computed: {
            ...mapGetters(['quaysNames', 'userInfo', 'groups', 'selectedGroup']),
        },
        created() {
            this.groups.length && this.$store.commit('setSelectedGroup', this.groups[0]);
        },
        methods: {
            handleClickGroup(group) {
                this.$store.commit('setSelectedGroup', group);
            },
            toggleModal() {
                this.isCreateModalShown = !this.isCreateModalShown;
            },
            async handleNewGroup(groupName) {
                const data = {
                    name: groupName,
                    created_by: this.userInfo.email,
                };

                try {
                    await this.$store.dispatch('storeGroup', data);
                    this.toggleModal();
                } catch (error) {
                    for (const value of Object.entries(error.response.data.errors)) {
                        this.$toasted.error(`${value[1]}`);
                    }
                }
            },
            handleRemoveGroup(groupId) {
                this.$store.dispatch('removeGroup', groupId);
            },
            createQuay(quay) {
                if (!this.selectedGroup.quays.find(item => item.id === quay.id)) {
                    const data = {
                        quay_id: quay.id,
                        group_id: this.selectedGroup.id,
                    };
                    this.$store.dispatch('storeGroupedQuay', data);
                } else {
                    this.$toasted.error('This quay already exists in the selected group');
                }
            },
            handleSelectQuay(quay) {
                if (quay.id !== 'New_Quay') {
                    this.createQuay(quay);
                }
            },
            handleRemoveQuay(quayId) {
                const payload = {
                    quayId,
                    groupId: this.selectedGroup.id,
                };
                this.$store.dispatch('removeGroupedQuay', payload);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/default/layout.scss';

    .quay-group {
        color: $white;
    }

    .container__title {
        font-size: $font-26;
        font-weight: 600;
        margin-bottom: $space-16;
    }

    .group-list {
        max-height: 315px;
        overflow-x: auto;
        padding-right: $space-16;
        margin-bottom: $space-16;
        @include scrollbar();
    }

    .container__item {
        width: 50%;
    }

    .group-item,
    .quay-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &:not(:last-child) {
            margin-bottom: $space-8;
        }
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
    }

    .group-item {
        padding: $space-8 $space-16;
        font-weight: 600;
        border: 1px solid $marine-500;
        border-radius: 6px;
    }

    .group-item_selected {
        background-color: $marine-700;
    }

    .quays-list {
        max-height: 315px;
        overflow-y: auto;
        background-color: $marine-700;
        border-radius: 6px;
        margin-bottom: $space-16;
        @include scrollbar();
    }

    .quay-item {
        margin: 0 $space-16;
        padding: $space-8 0;
        border-bottom: 1px solid $marine-500;

        &:last-child {
            border-bottom: none;
        }
    }

    .group-item__button,
    .quay-item__button {
        margin-left: $space-12;
    }

    .buttons-container {
        position: relative;
    }
</style>
