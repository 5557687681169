<template>
    <mf-box :empty="!selectedQuay">
        <div slot="header">
            Quay Relations
        </div>
        <div class="quay-relations">
            <div class="container__row">
                <div class="container__item" v-if="selectedQuay">
                    <template v-for="(quay, index) in selectedQuay.relations">
                        <div class="quay-item" :key="`related-quay-${index}`">
                            <div class="quay-item__details">
                                <div class="relate-name">{{ quay.name }}:</div>
                                <div class="quay-block">
                                    <div class="source-item">
                                        {{ selectedQuay.name }}
                                    </div>
                                    <div class="item-line"></div>
                                    <div class="destination_item">
                                        {{ quay.child.convertedName }}
                                    </div>
                                </div>
                            </div>
                            <mf-button class="quay-item__button" size="x-small" @click="handleRemoveRelation(quay.id)">
                                Remove
                            </mf-button>
                        </div>
                    </template>
                    <mf-button class="quay__add-button" size="x-small" @click="toggleCreateModal">
                        Add a new relation
                    </mf-button>
                </div>
            </div>
        </div>
        <create-quay-relation-modal
            :source-quay="selectedQuay"
            :is-open="isCreateModalShown"
            :key="`isCreateModalShown: ${isCreateModalShown}`"
            @close="toggleCreateModal"
            @newQuayRelation="handleAddNewRelation"
        />
        <div slot="empty-text">
            Quay not selected
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import CreateQuayRelationModal from '@/modals/CreateQuayRelationModal';

    export default {
        name: 'QuayRelations',
        components: {
            CreateQuayRelationModal,
        },
        data() {
            return {
                isCreateModalShown: false,
            };
        },
        computed: {
            ...mapGetters(['selectedQuay']),
        },
        methods: {
            toggleCreateModal() {
                this.isCreateModalShown = !this.isCreateModalShown;
            },
            handleAddNewRelation(event) {
                const data = {
                    name: event.relationName,
                    parent_id: this.selectedQuay.id,
                    child_id: event.quay.id,
                };

                if (
                    !this.selectedQuay.relations.find(
                        relation => relation.child.name === event.quay.name || relation.name === event.relationName
                    )
                ) {
                    this.$store.dispatch('storeRelation', data);
                    this.toggleCreateModal();
                } else {
                    this.$toasted.error('Relation with this Name or Quay already exists!');
                }
            },
            async handleRemoveRelation(relationId) {
                this.$store.dispatch('removeRelation', relationId);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .quay-item {
        display: flex;
        justify-content: space-between;
        color: $white;
        font-size: $font-18;
        margin-bottom: $space-12;
        padding: $space-16;
        border: 1px solid $marine-500;
        border-radius: 6px;
    }
    .quay-item__details {
        display: flex;
        align-items: center;
    }
    .relate-name {
        margin-right: $space-8;
    }
    .quay-block {
        display: flex;
        align-items: flex-end;
        font-size: $font-16;
    }
    .item-line {
        width: 30px;
        border-bottom: 1px solid;
        margin: 0 10px;
        align-self: center;
    }
    .quay__add-button {
        margin-top: $space-24;
    }
    .destination_item {
        font-weight: 600;
    }
</style>
